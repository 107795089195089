import { Box, Link } from "@mui/material";
import CornbreadKids from "../../Images/CornbreadKids-min.png";
import CornbreadKidsSquare from "../../Images/CornbreadKids-Square-min.jpg";
import GA4React from "ga-4-react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./puppets.module.scss";

type PuppetsProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const Puppets = ({ ga4react, isMobileDevice }: PuppetsProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 193px)`
        : `calc(${screenHeight}px - 340px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      alignContent="space-between"
      className={styles.pageContent}
      style={{ justifyContent: "center", height: pageContentHeight }}
      display="flex"
      flexDirection="row"
    >
      <Box
        sx={{
          fontSize: screenWidth <= 1250 ? "24px" : "28px",
          margin: "20px auto 0 auto",
          textAlign: "center",
          width: "80vw",
        }}
      >
        <span>To learn more Bible stories, check out the </span>
        <Link
          href="https://cornbreadkids.com"
          target="_blank"
          rel="noreferrer"
          underline="none"
          onClick={() =>
            ga4react.event(
              "Cornbread Kids Website Clicked",
              "Cornbread Kids Website",
              "Click",
              false
            )
          }
        >
          Cornbread Kids
        </Link>
        <span> puppet shows!</span>
      </Box>
      <Link
        href="https://www.youtube.com/@cornbreadkids"
        onClick={() =>
          ga4react.event(
            "Cornbread Kids YouTube Channel Clicked",
            "Cornbread Kids YouTube",
            "Click",
            false
          )
        }
        rel="noreferrer"
        target="_blank"
        underline="none"
      >
        <img
          className={styles.image}
          src={isMobileDevice ? CornbreadKidsSquare : CornbreadKids}
          style={{
            cursor: "pointer",
            //margin: isMobileDevice ? "auto 0 0 0" : "20px 0",
            //marginBottom: "-30px",
            //overflow: "hidden",
            width: "100%",
          }}
          alt="Cornbread Kids Bible story Puppet Shows"
        />
      </Link>
    </Box>
  );
};

export default Puppets;
