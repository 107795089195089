import AboutUs from "./components/aboutUs/AboutUs";
import banner from "./Images/Banner-min.jpg";
import { Box, Typography } from "@mui/material";
import GA4React from "ga-4-react";
import { getTabName } from "./shared/SharedFunctions";
import { menuChoices } from "./shared/enums";
import PageSelections from "./components/pageSelections/PageSelections";
import Puppets from "./components/puppets/Puppets";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Stories from "./components/stories/Stories";
import { useEffect, useState } from "react";
import useWindowDimensions from "./shared/windowDimensions";

import styles from "./app.module.scss";

type AppProps = {
  ga4react: GA4React;
};

function App({ ga4react }: AppProps) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  var isUpdating: boolean = false;
  const location = useLocation();
  const navigate = useNavigate();
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState(menuChoices.STORIES);

  useEffect(() => {
    if (isUpdating) {
      isUpdating = false;
      return;
    }

    isUpdating = true;

    switch (location.pathname.toLowerCase()) {
      case "/":
      case "/stories":
        if (selectedTab !== menuChoices.STORIES) {
          setSelectedTab(menuChoices.STORIES);
        }
        break;
      case "/about":
        if (selectedTab !== menuChoices.ABOUT)
          setSelectedTab(menuChoices.ABOUT);
        break;
      case "/puppets":
        if (selectedTab !== menuChoices.PUPPETS)
          setSelectedTab(menuChoices.PUPPETS);
        break;
    }
  }, [location]);

  useEffect(() => {
    setIsMobileDevice(screenWidth < 700);
  }, [screenWidth]);

  useEffect(() => {
    if (isUpdating) {
      isUpdating = false;
      return;
    }

    isUpdating = true;

    if (
      selectedTab === menuChoices.STORIES &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/stories"
    ) {
      navigate("/");
    } else if (
      selectedTab === menuChoices.ABOUT &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/about"
    ) {
      navigate("/About");
    } else if (
      selectedTab === menuChoices.PUPPETS &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/puppets"
    ) {
      navigate("/Puppets");
    }

    ga4react.event(
      `Tab Clicked : ${getTabName(selectedTab)}`,
      getTabName(selectedTab) as string,
      "Click",
      false
    );
  }, [selectedTab]);

  return (
    <Box className={styles.app}>
      {isMobileDevice && (
        <Box
          sx={{
            borderBottom: "1px solid #A8A8B7",
            minWidth: "400px",
            overflow: "hidden",
            height: "120px",
          }}
        >
          <img
            style={{
              display: "block",
              height: "100%",
              margin: "0 auto",
              objectFit: "cover",
              transform: "scale(1.5",
              width: "100%",
            }}
            src={banner}
            alt="Bible Bedtime Stories for Kids"
          />
        </Box>
      )}
      {!isMobileDevice && (
        <Box
          sx={{
            borderBottom: "1px solid #A8A8B7",
            minWidth: "400px",
            overflow: "hidden",
            height: "250px",
          }}
        >
          <img
            style={{
              display: "block",
              height: "100%",
              margin: "0 auto",
              maxWidth: "1400px",
              objectFit: "cover",
              transform: "scale(1.1",
              width: "100%",
            }}
            src={banner}
            alt="Bible Bedtime Stories for Kids"
          />
        </Box>
      )}

      <PageSelections
        ga4react={ga4react}
        isMobileDevice={isMobileDevice}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <Box className={styles.pageContent}>
        <Routes>
          <Route
            path="/"
            element={
              selectedTab === menuChoices.STORIES ? (
                <Stories ga4react={ga4react} isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.ABOUT ? (
                <AboutUs isMobileDevice={isMobileDevice} />
              ) : (
                <Puppets ga4react={ga4react} isMobileDevice={isMobileDevice} />
              )
            }
          />
          <Route
            path="/Stories"
            element={
              <Stories ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
          <Route
            path="/About"
            element={<AboutUs isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="/Puppets"
            element={
              <Puppets ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
          <Route
            path="*"
            element={
              <Stories ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
        </Routes>
      </Box>

      <Box className={styles.footer}>
        <Typography
          sx={{ color: "white", fontSize: "12px", marginBottom: "2px" }}
        >
          Copyright © 2024 Mud Pie Creations. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
