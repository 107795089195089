import { Box, Card, CardActions, Link, Typography } from "@mui/material";
import { format } from "date-fns";
import GA4React from "ga-4-react";
import { StoryInfo } from "../../shared/models/StoryInfo";
import { storyInfoChoices } from "../../shared/enums";
import YouTubePlayButton from "../../Images/YouTubePlayButton-min.png";

import styles from "./stories.module.scss";

type MoreStoryInfoProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
  screenHeight: number;
  screenWidth: number;
  selectedStory: StoryInfo;
  selectedTab: number;
};

const MoreStoryInfo = ({
  ga4react,
  isMobileDevice,
  screenHeight,
  selectedStory,
  selectedTab,
}: MoreStoryInfoProps) => {
  return (
    <Box
      className={styles.moreInfoContainer}
      sx={{
        height: `calc(${screenHeight}px - 290px)`,
        margin: "0 auto 5px auto",
        width: "350px",
      }}
    >
      {selectedTab === storyInfoChoices.CHAPTERS && (
        <Box>
          {selectedStory?.Chapters?.map((chapter) => (
            <Card
              key={chapter.Chapter}
              className={styles.cards}
              sx={{
                border: "1px solid black",
                borderRadius: "12px",
                height: "225px",
                margin: isMobileDevice ? "10px 10px 0 10px" : "10px 0 0 0",
              }}
            >
              <CardActions className={styles.cardActions}>
                <Link
                  href={`https://youtu.be/${selectedStory.YouTubeId}&mute=1`}
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  onClick={() =>
                    ga4react.event(
                      `Video Clicked: ${selectedStory.Title}`,
                      selectedStory.Title,
                      "Click",
                      false
                    )
                  }
                >
                  <Box className={styles.cardActionContainer}>
                    <img
                      src={`https://img.youtube.com/vi/${chapter.YouTubeId}/mqdefault.jpg`}
                      alt={selectedStory.Title}
                    />
                    <Typography
                      sx={{
                        color: "#00000099",
                        marginRight: "auto",
                        marginTop: "10px",
                      }}
                    >
                      {`published: ${format(
                        new Date(chapter.PublishDate),
                        "MM/dd/yyyy"
                      )}`}
                    </Typography>
                    <img
                      style={{
                        marginLeft: "140px",
                        marginTop: "-140px",
                        width: "50px",
                        zIndex: "7",
                      }}
                      src={YouTubePlayButton}
                      alt="Play video on YouTube"
                    />
                  </Box>
                </Link>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
      {selectedTab === storyInfoChoices.DESCRIPTION && (
        <Box className={styles.moreInfo} padding="20px">
          {selectedStory.Description.split("\n").map((item, idx) => (
            <span key={idx}>
              <Typography sx={{ fontSize: "18px" }}>{item}</Typography>
              <br />
            </span>
          ))}
        </Box>
      )}
      {selectedTab === storyInfoChoices.BUYBOOK && (
        <Box
          className={styles.moreInfo}
          sx={{ flexDirection: "column", margin: "0 20px 20px 20px" }}
        >
          <Typography sx={{ fontSize: "18px", marginTop: "21px" }}>
            If you'd like a copy of this book, you can order it on
            <Link
              href={selectedStory.BuyLink}
              onClick={() =>
                ga4react.event(
                  `Buy Button Clicked: ${selectedStory.Title}`,
                  "Buy",
                  "Click",
                  false
                )
              }
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "7px" }}
            >
              Amazon
            </Link>
          </Typography>
          <Box
            border="1px solid black"
            height="fit-content"
            margin="30px auto"
            width="fit-content"
          >
            <img
              style={{
                height: "300px",
                objectFit: "cover",
              }}
              src={`https://BibleBedtimeStories4Kids.com/bookImages/${selectedStory.BookImage}`}
              alt={selectedStory.Title}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MoreStoryInfo;
