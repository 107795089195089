import { menuChoices } from "./enums";

export const getTabName = (selectedTab: number) => {
  switch (selectedTab) {
    case menuChoices.ABOUT:
      return "ABOUT US";
    case menuChoices.PUPPETS:
      return "PUPPETS";
    case menuChoices.STORIES:
      return "STORIES";
  }
};
