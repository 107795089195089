import { Box, Typography } from "@mui/material";
import KidsReading from "../../Images/KidsReading-min.png";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./aboutUs.module.scss";

type AboutUsProps = {
  //ga4: any,
  isMobileDevice: boolean;
};

const AboutUs = ({ isMobileDevice }: AboutUsProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 193px)`
        : `calc(${screenHeight}px - 345px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box
      className={styles.pageContent}
      style={{
        justifyContent: "center",
        height: pageContentHeight,
      }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      <Box
        sx={{
          display: "flex",
          margin: "auto 30px",
          paddingTop: "7px",
        }}
      >
        <img
          style={{
            width: isMobileDevice ? "80vw" : "40vw",
            margin: "auto",
            objectFit: "fill",
          }}
          src={KidsReading}
          alt="Learn fun and exciting stories from the Bible.  Although the characters in the narratives are fictional, the very real stories from the Bible are accurately conveyed.  Follow along with the words on the screen to improve your reading abilities or just enjoy the illustrations and listening to the story."
        />
      </Box>
      <Box
        height="fit-content"
        width={isMobileDevice ? "85vw" : "50vw"}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "auto 10px auto 10px",
        }}
      >
        <Typography
          sx={{
            flexGrow: "1",
            fontSize: isMobileDevice ? "24px" : "28px",
          }}
        >
          Learn fun and exciting stories from the Bible. Although the characters
          in the narratives are fictional, the very real stories from the Bible
          are accurately conveyed. Follow along with the words on the screen to
          improve your reading abilities or just enjoy the illustrations and
          listening to the story.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUs;
