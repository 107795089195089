export const menuChoices = {
  STORIES: 0,
  ABOUT: 1,
  PUPPETS: 2,
};

export const storyInfoChoices = {
  CHAPTERS: 0,
  DESCRIPTION: 1,
  BUYBOOK: 2,
};
