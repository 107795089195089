import {
  Box,
  Link,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import GA4React from "ga-4-react";
import { getTabName } from "../../shared/SharedFunctions";
import { menuChoices } from "../../shared/enums";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
//import youTubeShare from "../../Images/YouTubeShare-min.png";
import youTubeSubscribe from "../../Images/YouTubeSubscribe-min.png";

import styles from "./pageSelections.module.scss";

type PageSelectionsProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
  selectedTab: number;
  setSelectedTab: Function;
};

const PageSelections = ({
  ga4react,
  isMobileDevice,
  selectedTab,
  setSelectedTab,
}: PageSelectionsProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isMobileMenuDisplayed = Boolean(menuAnchorEl !== null);

  const mobileMenuSelected = (selectedMenu: number) => {
    setSelectedTab(selectedMenu);
    setMenuAnchorEl(null);
  };

  const onDisplaySettingsMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      {isMobileDevice && (
        <>
          <Box className={styles.mobileMenuBar}>
            <MenuIcon
              className={styles.menuIcon}
              onClick={onDisplaySettingsMenu}
              sx={{ height: "45px", margin: "2px 7px", width: "50px" }}
            />
            <Typography
              sx={{
                color: "inherit",
                fontSize: "20px",
                fontWeight: "500",
                margin: "auto 10px",
              }}
            >
              {getTabName(selectedTab)}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              margin="3px 12px 0 auto"
            >
              <Link
                href={
                  "https://www.youtube.com/@biblebedtimestoriesforkids/?sub_confirmation=1"
                }
                onClick={() =>
                  ga4react.event(
                    "Subscribe Button Clicked",
                    "Subscribe",
                    "Click",
                    false
                  )
                }
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                {/* <Tooltip
                  title="Subscribe to the Bible Bedtime Stories YouTube Channel"
                  sx={{ fontSize: "14px" }}
                > */}
                <img
                  style={{ height: "32px", marginLeft: "20px" }}
                  src={youTubeSubscribe}
                  alt="Subcribe to the Bible Bedtime Stories YouTube channel"
                />
                {/* </Tooltip> */}
              </Link>
              {/* <img style={{height:'48px', margin:'0 0 0 10px'}} src={youTubeShare} alt="Share the Bible Bedtime Stories YouTube channel" /> */}
            </Box>
          </Box>

          <Menu
            anchorEl={menuAnchorEl}
            open={isMobileMenuDisplayed}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.STORIES)}
            >
              STORIES
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.ABOUT)}
            >
              ABOUT US
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.PUPPETS)}
            >
              PUPPETS
            </MenuItem>
          </Menu>
        </>
      )}

      {!isMobileDevice && (
        //
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Tabs
            className={styles.tabs}
            value={selectedTab}
            centered
            onChange={(event, value) => {
              if (value !== selectedTab) {
                setSelectedTab(value);
              }
            }}
            sx={{
              "& .MuiTab-root": {
                fontSize: "16px;",
                fontWeight: "600",
              },
            }}
          >
            <Tab label="Stories" />
            <Tab label="About Us" />
            <Tab label="Puppets" />
          </Tabs>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop="20px"
          >
            <Link
              href={
                "https://www.youtube.com/@biblebedtimestoriesforkids/?sub_confirmation=1"
              }
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              <img
                style={{ height: "32px", marginLeft: "70px" }}
                src={youTubeSubscribe}
                alt="Subcribe to the Bible Bedtime Stories YouTube channel"
              />
            </Link>
            {/* <img style={{height:'48px', margin:'0 0 0 10px'}} src={youTubeShare} alt="Share the Bible Bedtime Stories YouTube channel" /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PageSelections;
