import { AppSettings } from "../../shared/models/AppSettings";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GA4React from "ga-4-react";
import MenuIcon from "@mui/icons-material/Menu";
import MoreStoryInfo from "./MoreStoryInfo";
import { StoryInfo } from "../../shared/models/StoryInfo";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";
import YouTubePlayButton from "../../Images/YouTubePlayButton-min.png";

import styles from "./stories.module.scss";

type StoriesProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const Stories = ({ ga4react, isMobileDevice }: StoriesProps) => {
  const [announcement, setAnnouncement] = useState("");
  const isDevelopment =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  const [isMoreInfoShowing, setIsMoreInfoShowing] = useState(false);
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedStory, setSelectedStory] = useState({} as StoryInfo);
  const [selectedTab, setSelectedTab] = useState(0);
  const [storyList, setStoryList] = useState([] as StoryInfo[]);

  useEffect(() => {
    getAnnouncement();
    getStories();
  }, []);

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 193px)`
        : `calc(${screenHeight}px - 340px)`
    );
  }, [isMobileDevice, screenHeight]);

  const getAnnouncement = async () => {
    await fetch(
      `https://BibleBedtimeStories4Kids.com/appSettings.json?nocache=${new Date().getTime()}`
    )
      .then((response) => response.json())
      .then((response) => {
        var appSettings = response as AppSettings;
        setAnnouncement(appSettings.Announcement);
      })
      .catch((err) => console.error(err));
  };

  const getStories = async () => {
    await fetch(
      `https://BibleBedtimeStories4Kids.com/stories.json?nocache=${new Date().getTime()}`
    )
      .then((response) => response.json())
      .then((response) => {
        var stories = response as StoryInfo[];
        setStoryList(stories);
      })
      .catch((err) => console.error(err));
  };

  const moreInfoButtonClicked = async (event: any, storyId: number) => {
    try {
      event.stopPropagation();

      var storyInfo = storyList.find((story) => story.Id === storyId);

      if (storyInfo == null) return;

      setSelectedStory(storyInfo);
      setSelectedTab(0);
      setIsMoreInfoShowing(true);

      ga4react.event(
        `More Info Clicked: ${storyInfo.Title}`,
        storyInfo.Title,
        "Click",
        false
      );
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return (
    <Box
      className={styles.pageContent}
      style={{
        alignContent: "flex-start",
        height: pageContentHeight,
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <Box height="fit-content">
        <Typography
          className={styles.description}
          sx={{
            color: "black",
            fontSize: "20px",
            margin: isMobileDevice ? "10px 30px 0 30px" : "10px 60px 0 60px",
          }}
        >
          Enjoy the illustrations and follow along as we read bedtime stories
          from the Bible!
        </Typography>
      </Box>
      {announcement?.length > 0 && (
        <Typography
          className={styles.description}
          sx={{
            color: "#0053a4",
            fontSize: isMobileDevice ? "16px" : "20px",
            fontWeight: "bold",
            margin: isMobileDevice ? "15px 25px 0 25px" : "15px 60px 0 60px",
            width: "98%",
          }}
        >
          {announcement}
        </Typography>
      )}
      <Box
        className={styles.pageContent}
        style={{
          justifyContent: "center",
          margin: "10px auto",
        }}
      >
        {storyList.map((story) => (
          <Card
            key={story.Id}
            className={styles.cards}
            sx={{
              border: "1px solid black",
              borderRadius: "12px",
              color: "#00000099",
              height: "265px",
              margin: "15px",
              width: "320px",
            }}
          >
            <CardContent>
              <Box className={styles.cardTitle}>
                <Typography
                  sx={{
                    color: "inherit",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {story.Title}
                </Typography>
                <Tooltip
                  title={
                    <Typography fontSize="14px">Display More Info</Typography>
                  }
                  sx={{ fontSize: "14px" }}
                >
                  <MenuIcon
                    className={styles.menuIcon}
                    sx={{
                      height: isMobileDevice ? "45px" : "25px",
                      marginLeft: "auto",
                      width: isMobileDevice ? "50px" : "30px",
                    }}
                    onClick={(event) => moreInfoButtonClicked(event, story.Id)}
                  />
                </Tooltip>
              </Box>
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Link
                href={`https://www.youtube.com/playlist?list=${story.PlaylistId}`}
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() =>
                  ga4react.event(
                    `Playlist Clicked: ${story.Title}`,
                    story.Title,
                    "Click",
                    false
                  )
                }
              >
                <Box className={styles.cardActionContainer}>
                  {/* <img
                    src={`https://img.youtube.com/vi/${story.YouTubeId}/mqdefault.jpg`}
                    alt={story.Title}
                  /> */}
                  <img
                    style={{
                      height: "100%",
                      maxHeight: "170px",
                    }}
                    src={`https://BibleBedtimeStories4Kids.com/bookImages/${story.BookImage}`}
                    alt={selectedStory.Title}
                  />
                  <Tooltip
                    title={
                      <Typography fontSize="14px">
                        Display the YouTube Playlist for this Story
                      </Typography>
                    }
                  >
                    <img
                      style={{
                        marginLeft: "65px",
                        marginTop: "-140px",
                        width: "50px",
                        zIndex: "7",
                      }}
                      src={YouTubePlayButton}
                      alt="Play video on YouTube"
                    />
                  </Tooltip>
                </Box>
              </Link>
            </CardActions>
          </Card>
        ))}
        <Dialog
          open={isMoreInfoShowing}
          onClose={() => setIsMoreInfoShowing(false)}
        >
          <Box className={styles.stickyHeader}>
            <Box className={styles.moreInfoHeader}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobileDevice ? "16px" : "18px",
                  fontWeight: "bold",
                  margin: "30px 0 10px 15px",
                }}
              >
                {selectedStory.Title}
              </Typography>
              <CloseIcon
                className={styles.closeButton}
                sx={{
                  cursor: "pointer",
                  height: "35px",
                  margin: "10px",
                  width: "35px",
                }}
                onClick={() => setIsMoreInfoShowing(false)}
              />
            </Box>
            {selectedStory.SubTitle && (
              <Typography
                sx={{
                  color: "black",
                  fontSize: isMobileDevice ? "14px" : "16px",
                  margin: "0 20px 10px 15px",
                }}
              >
                {selectedStory.SubTitle}
              </Typography>
            )}
            <Tabs
              className={styles.tabs}
              value={selectedTab}
              centered
              onChange={(event, value) => setSelectedTab(value)}
              sx={{
                position: "sticky",
                top: "0",
                "& .MuiTab-root": {
                  fontSize: "18px;",
                  fontWeight: "600",
                  padding: isMobileDevice ? "12px 7px" : "12px 16px",
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Chapters" />
              <Tab label="Description" />
              <Tab label="Buy Book" />
            </Tabs>
          </Box>

          <MoreStoryInfo
            ga4react={ga4react}
            isMobileDevice={isMobileDevice}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            selectedStory={selectedStory}
            selectedTab={selectedTab}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default Stories;
